import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import StatusAlert, { StatusAlertService } from 'react-status-alert';

import Wallet from './Wallet';
import Footer from './Footer';
import Header from './Headerrr';
import { isPresaleMint } from '../service';
import { showCanvas } from '../other/helper';


export default class Mint extends React.Component<{},{ismintlive:boolean}> {
    constructor(props: any) {
        super(props);

        this.state = {
            ismintlive:false
        };
        this.getSale();
    }
     getSale(){
        isPresaleMint().then((res:any)=>{
          console.log(res.data.result.isMintLive)
          this.setState({ismintlive:res.data.result.isMintLive}) 
        //   let isPre = res.data.result.isPresaleMint !=0 
        //   setPresale(isPre)
        //   setMintLive(mintLive)
        })
        .catch((e)=>console.log(e))
      }
    render() {
        showCanvas()
        return <div>
            {/* header separate for min page as in the previous pro absence of router and usage of href tags separate header is being used rather than disturbing everything  */}

            <Header></Header>
            <div className="section full-height height-auto-lg hide-over background-dark-blue-3 ">
                <div className="hero-center-wrap relative-on-lg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 text-center text-lg-left parallax-fade-top align-self-center z-bigger">
                                <h2 className="text-white">Connect Your Wallet and Mint</h2>
                               { this.state.ismintlive && 
                                    <Wallet />
                               }
                               {!this.state.ismintlive &&
                                    <h3 className="text-white">Mint is not live yet</h3>
                               }
                            </div>
                            <div className="col-lg-6 mt-5 mt-lg-0 rounded-500">
                          
                            <Carousel
                                className='my-3 mx-3'
                                autoPlay={false}
                                centerMode={false}
                                infiniteLoop={false}
                                interval={2000}
                                swipeable = {false}
                                showThumbs={false}
                                showStatus={false}
                                showArrows={false}>
                                <div >
                                    <img className='rounded' src="./images/gif.gif" />
                                </div>
                               
                                <div >
                                    <img className='rounded' src="./images/gif.gif" />
                                </div>
                            </Carousel>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="particles-js" className="min-height"></div>
            </div>


            <div className="">
                <StatusAlert />
            </div>
            
            <Footer />
        </div>
    }
}